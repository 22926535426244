import { Button, Flex, Icon, Text, userInterfaceNotificationsStore } from "@engaging-tech/components"
import { usePaths, useRouter } from "@engaging-tech/routing"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`
const StyledButton = styled(Button)`
  background: none;
  box-shadow: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
  :active {
    text-decoration: none;
    cursor: pointer;
  }
`

const VoiceAward = () => {
  const history = useHistory()
  const paths = usePaths()
  const router = useRouter()
  const dispatch = useDispatch()

  const toAwards = () => {
    if (history.location.pathname.includes("/in-app-view")) {
      router.navigate(paths.inAppViewHappinessSurvey.rankYourCompany)
    } else {
      router.navigate(paths.happinessSurvey.rankYourCompany)
    }
  }

  const copyToClipboard = () => {
    if (history.location.pathname.includes("/in-app-view")) {
      navigator.clipboard.writeText("https://app.workl.co/in-app-view/happiness-survey/rank-company")
    } else {
      navigator.clipboard.writeText("https://app.workl.co/happiness-survey/rank-company")
    }
  }

  const handleOnclick = () => {
    copyToClipboard()
    dispatch(
      userInterfaceNotificationsStore.actions.addNotification({
        message: "Copied to clipboard"
      })
    )
  }
  return (
    <Flex flexDirection="column" bg="secondary.3" width="100%" p={3}>
      <Flex mb={3} flexDirection={["column", "row", "row"]} alignItems="center">
        <StyledImg
          // src="https://media.engaging.works/production/media/companylogos/HappiestWorkplaces.png"
          src="https://engaging-works.s3.eu-west-2.amazonaws.com/production/media/companylogos/HappiestWorkplaces2024.png"
          alt="Happiest Companies Awards 2023"
          width="300px"
        />
        <Flex flexDirection="column" ml={3}>
          <Text color="dark.2" my={4}>
            Enter your workplace
          </Text>
          <Button
            variant="primary.0"
            width={["97%", "100%", "100%"]}
            border="4px solid #1be96e"
            onClick={() => {
              if (typeof window !== "undefined" && window.gtag) {
                window.gtag("event", "awards_test", {
                  event_category: "button_click",
                  event_label: "Awards Test"
                })
              }
              toAwards()
            }}
          >
            Happy At Work Test
          </Button>
        </Flex>
      </Flex>
      <Flex flexDirection="column" px={3}>
        <Text color="dark.2" mb={3}>
          Boost your workplaces chance of being recognised in our annual Happiest Workplaces List by sharing
          the link to the survey with colleagues, friends and family.
        </Text>
        <Flex width={1 / 1} justifyContent="flex-end">
          <StyledButton
            textAlign="right"
            onClick={() => {
              if (typeof window !== "undefined" && window.gtag) {
                window.gtag("event", "copy_survey_link", {
                  event_category: "button_click",
                  event_label: "Copy Link"
                })
              }
              handleOnclick()
            }}
          >
            <Icon name="link" pr="11px" />
            Copy Link
          </StyledButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default VoiceAward
